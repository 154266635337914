<template>
  <!-- 授课记录 -->
  <div v-loading="allLoading" class="container-wrap">
    <span class="teachData-main-desc">
      * {{ $t("el.common.dataStatistics") }}</span
    >
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :tableOptions="{
        height: tableHeight,
      }"
      @changeQueryParams="changeQueryParams"
      noEmptyText="el.common.noData"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.search") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          type="primary"
          @click="handleExport()"
          >{{ $t("el.common.export") }}</el-button
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import {
  schoolTree,
  classTree,
  getTeachClassMerge,
} from "@/api/home/teachmanage";
import { handparams } from "../../../../utils";
import moment from "moment";
export default {
  // 定义数据
  data() {
    return {
      tableHeight: undefined,
      schoolList: [],
      dialogForm: {},
      visible: false,
      canCommit: true,
      allLoading: false,
      classOptions: [], // 授课班级 options
      isSelectDefault: true, // 是否默认选择学校/学部
      nowSchoolCode: "", // 当前学校/学部
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  // 计算属性
  computed: {
    ...mapState({
      userInfo: (state) => state.user,
    }),
    ...mapGetters(["attendSchoolList"]),
    effectiveHoursList() {
      return [
        {
          value: 1,
          label: this.$t("el.common.noLess10M"),
        },
        {
          value: 2,
          label: this.$t("el.common.noLess30M"),
        },
      ];
    },
    /**
     * 定义列
     */

    columns() {
      let self = this;
      return [
        {
          label: this.$t("el.common.teacherName"),
          prop: "staffName",
          width: "80px",
          fixed: true,
        },
        {
          label: this.$t("el.infrastructure.mobilePhoneNumber"),
          prop: "mobile",
          width: "110px",
          fixed: true,
        },
        {
          label: this.$t("el.common.TeachingClass"),
          prop: "className",
          "min-width": "140px",
        },
        {
          label: this.$t("el.common.Course"),
          prop: "courseName",
          "min-width": "140px",
        },
        {
          label: this.$t("el.courseDetails.keyPoints"),
          prop: "knowledgeName",
          "min-width": "140px",
        },
        {
          label: this.$t("el.common.startTime"),
          prop: "startTime",
          formatter(row, column, value) {
            return moment(value).format("yyyy-MM-DD HH:mm:ss");
          },
          width: "160px",
        },
        {
          label: this.$t("el.common.endTime"),
          prop: "endTime",
          formatter(row, column, value) {
            return moment(value).format("yyyy-MM-DD HH:mm:ss");
          },
          width: "160px",
        },
        // {
        //   label: this.$t("el.planCourse.state"),
        //   prop: "studentStatus",
        //   formatter(row, column, value) {
        //     let formateVal;
        //     switch (Number(row.studentStatus)) {
        //       case 1:
        //         formateVal = self.$t("el.infrastructure.inClass");
        //         break;
        //       case 2:
        //         formateVal = self.$t("el.infrastructure.Transfer");
        //         break;
        //       case 3:
        //         formateVal = self.$t("el.infrastructure.dropSchool");
        //         break;
        //       case 4:
        //         formateVal = self.$t("el.infrastructure.outSchool");
        //         break;
        //       case 5:
        //         formateVal = self.$t("el.infrastructure.graduation");
        //         break;
        //       default:
        //         formateVal = "";
        //         break;
        //     }
        //     return formateVal;
        //   },
        // },
        {
          label: this.$t("el.common.cumulativeDuration"),
          prop: "duration",
          width: "100px",
          formatter(row, column, cellValue) {
            return cellValue ? cellValue + self.$t("el.common.minutes") : "";
          },
        },

        // {
        //   label: this.$t("el.planCourse.classTimeNo"),
        //   prop: "classTime",
        //   formatter(row, column, value) {
        //     return (
        //       (row.startTime && row.startTime.substring(0, 5)) +
        //       "~" +
        //       (row.endTime && row.endTime.substring(0, 5))
        //     );
        //   },
        // },

        // {
        //   label: this.$t("el.common.operate"),
        //   slotName: "otherHandle",
        // },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "input",
          prop: "staffName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.common.teacherName"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "mobile",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.infrastructure.mobilePhoneNumber"),

          clearable: true,
        },
        // {
        //   itemType: "select",
        //   prop: "schoolCode",
        //   size: "small",
        //   placeholder: this.$t("el.common.pleaseSelect"),
        //   label: this.$t("el.MyHome.depart"),
        //   "popper-append-to-body": false,
        //   filterable: true,
        //   // props: {
        //   //   value: "schoolCode",
        //   //   label: "schoolName",
        //   //   children: "children",
        //   //   filterable: true,
        //   //   // multiple: true,
        //   // },
        //   options: this.schoolList,
        //   selectDefault: this.isSelectDefault,
        // },
        {
          itemType: "select",
          prop: "classId",
          size: "small",
          options: this.classOptions,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.common.TeachingClass"),
          clearable: true,
          "append-to-body": false,
          //   props: {
          //     value: "code",
          //     label: "name",
          //     children: "children",
          //      multiple: true,
          //   },
        },
        {
          itemType: "input",
          prop: "courseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.common.Course"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "knowledgeName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseDetails.keyPoints"),
          clearable: true,
        },
        {
          itemType: "daterange",
          prop: "courseDateRange",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd HH:mm:ss",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.dataAnalysis.teachingTime"),
          "append-to-body": false,
          clearable: true,
        },
        {
          itemType: "numberRange",
          prop1: "minDuration",
          prop2: "maxDuration",
          prop: "duration",
          size: "small",
          label: this.$t("el.MyHome.todayTimes"),
        },
      ];
    },
  },
  watch: {},
  methods: {
    async init() {
      this.query();
      // this.getAuthSchoolList();
      this.getClassTree(); // TODO: 待定
    },

    changeQueryParams(newVal, oldVal) {
      // if (newVal.schoolCode) {
      //   if (newVal.schoolCode !== oldVal.schoolCode) {
      //     this.getClassTree(newVal.schoolCode);
      //   }
      //   if (newVal.schoolCode !== this.nowSchoolCode) {
      //     this.isSelectDefault = false;
      //     this.nowSchoolCode = newVal.schoolCode;
      //   }
      // } else {
      //   this.classOptions = [];
      //   newVal.classId = "";
      // }
    },

    // 获取授课班级选项 TODO: 待定
    getClassTree(schoolCode) {
      return classTree(schoolCode)
        .then(({ data }) => {
          let a = [];
          if (data instanceof Array) {
            data.forEach((element) => {
              element.children.forEach((ele) => {
                ele.value = ele.code;
                ele.label = element.name + "-" + ele.name;
                a.push(ele);
              });
            });
          }
          a.push({
            value: 0,
            label: this.$t("el.common.None"),
          });
          this.classOptions = a || [];
        })
        .catch(() => {
          this.classOptions = [];
        });
    },
    /**
     * 筛选
     */
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    /**
     * 导出
     */
    async handleExport() {
      let queryParams = this.$refs.xmTable.getQueryParams(true);
      console.log(queryParams);

      delete queryParams.courseDateRange;

      //   this.$utils.download("/teachClassMerge/exportTeachManage", queryParams);
      this.allLoading = true;
      //   if (this.$refs.xmTable.getNowDataList().length > 0) {
      try {
        await this.$utils.exportDownCommon(
          "get",
          handparams(queryParams),
          "/teachClassMerge/exportTeachManage",
          `${this.userInfo.tenantName}-授课记录-${new Date().getTime()}.xlsx`
        );
      } catch (error) {
        //
      }
      //   } else {
      //     this.$message.warning(this.$t("el.common.noData"));
      //   }

      this.allLoading = false;
    },

    /**
     * 重置
     */
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },

    resetFormCallback(queryParams) {
      // console.log('queryParams',queryParams);
      // this.setDefaultSchool(queryParams, this.schoolList);
    },

    //
    // setDefaultSchool(queryParams, schoolList) {
    //   let hasDefault = false;
    //   schoolList.forEach((element) => {
    //     if (element.defaultSchool) {
    //       this.$set(queryParams, "schoolCode", element.schoolCode);
    //       // queryParams.schoolCode = element.schoolCode;
    //       hasDefault = true;
    //     }
    //   });
    //   if (!hasDefault) {
    //     if (schoolList && schoolList.length > 0) {
    //       this.$set(queryParams, "schoolCode", schoolList[0].schoolCode);
    //     }
    //     // queryParams.schoolCode = schoolList[0].schoolCode;
    //   }
    // },

    // 获取学校列表
    // async getAuthSchoolList() {
    //   await this.$store.dispatch("getSchoolList");

    //   this.schoolList = this.attendSchoolList;
    //   if (this.schoolList.length > 0) {
    //     this.isSelectDefault = true;
    //     this.nowSchoolCode = this.attendSchoolList[0].schoolCode;
    //     this.$nextTick(() => {
    //       this.query();
    //     });
    //   }
    //   return this.attendSchoolList;
    // },

    cancel() {
      this.visible = false;
    },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      console.log("params1212", params);
      if (!params.courseDateRange) {
        params.startTime = params.endTime = "";
      }
      let data = handparams(params);

      delete data.courseDateRange;

      return getTeachClassMerge(data)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },

    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.container-wrap {
  position: relative;
  background: #ffffff;
  padding: 50px 50px 30px;
  border-radius: 10px;

  .teachData-main-desc {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 14px;
    color: #807f7f;
  }

  /deep/ .el-form-item {
    margin-bottom: 12px;
  }

  /deep/ .el-button--small,
  .el-button--small.is-round {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/deep/ .el-dialog {
  margin-top: 24 px !important;
  margin-bottom: 24 px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
}
</style>
